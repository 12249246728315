<template>
	<nav class="manager-navbar col">
		<router-link class="logo" to="/">
			<img src="@/assets/logo.png" width="100%">
		</router-link>
		<div class="menu col" gutter="15">
			<template v-for="(item, i) in list">
				<el-divider v-if="item.divided" :key="`divider-${i}`" />
				<div class="col" :key="i">
					<router-link class="menu-item" :to="`/manager/${item.path}`">{{item.label}}</router-link>
					<div v-if="item.children" class="sub-menu col">
						<template v-for="(subItem, j) in item.children">
							<router-link class="sub-menu-item" :to="`/manager/${item.path}/${subItem.path}`" :key="`${i}-${j}`">{{subItem.label}}</router-link>
						</template>
					</div>
				</div>
			</template>
		</div>
	</nav>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					path: "home",
					label: "首頁",
				},
				{
					path: "about",
					label: "關於金枝",
					children: [
						{
							path: "index",
							label: "關於金枝演社",
						},
						{
							path: "founder",
							label: "創辦人暨藝術總監",
						},
						{
							path: "mother",
							label: "金枝的阿母",
						},
					]
				},
				{
					path: "activity",
					label: "最新活動",
				},
				{
					path: "news",
					label: "焦點消息"
				},
				{
					path: "portfolio",
					label: "系列作品"
				},
				{
					path: "perform",
					label: "金枝走演",
					children: [
						{
							path: "event",
							label: "場次紀錄",
						},
					]
				},
				{
					divided: true,
					path: "product",
					label: "金枝百貨舖"
				},
				{
					path: "order",
					label: "查看訂單"
				},
				{
					divided: true,
					path: "account/member-manage",
					label: "金枝會員",
					children: [
						{
							path: "list",
							label: "全部",
						},
						{
							path: "apply-pending",
							label: "待處理",
						},
					]
				},
				{
					path: "support",
					label: "支持金枝捐款列表"
				},
				{
					path: "subscribe",
					label: "金枝電子報",
					children: [
						{
							path: "subscriber",
							label: "訂閱者",
						},
						// {
						// 	path: "template",
						// 	label: "信件模板",
						// },
					]
				},
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.manager-navbar {
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
	padding: 1em 2em;
	padding-bottom: 400px;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	.logo {
		width: 7em;
		margin-bottom: 30px;
	}

	.menu {
		font-size: 1.2em;
		color: #303133;

		.sub-menu {
			padding-left: 1em;
			color: #909399;
			font-size: smaller;
		}

		.sub-menu-item {
			margin-top: 10px;
		}
	}
}
</style>

<style lang="scss" scoped>
.el-divider {
	margin: 0.8em 0;
	padding: 0 !important;
}
.router-link-active {
	color: $theme;
	text-decoration: underline;
}
</style>